<template>
    <div>
        <div class="mainContainer">
            <div class="bottomBorder" style="display: flex; justify-content: space-between; align-items: center; ">
                <!-- <div style="font-weight: 500; font-size: 14px;">Select Specialty</div>
                <v-btn  outlined style=" color: #1467BF;" @click="() => {specialitySheet = true;}">Select</v-btn> -->
                <OPDPackageSelectSpecialty  :tabName="'HOSPITALOPDFLOW'"/>
            </div>
            <!-- <div class="bottomBorder" style="display: flex; justify-content: space-between; align-items: center; padding: 12px 16px 12px 16px;">
                <div style="font-weight: 500; font-size: 14px;">Show doctors with slot</div>
                <v-switch ></v-switch>
            </div> -->
            <div class="bottomBorder" style="display: flex; justify-content: space-between; align-items: center; padding: 12px 16px 12px 16px;">
                <div style="width: 100%; display: flex; gap: 8px;">
                <div class="input-wrapper" style="flex-grow: 1; background-color: transparent;" id="searchBox">
                    <img src="https://s3iconimages.mymedicine.com.mm/searchIcon.svg" alt="Icon" class="input-icon">
                    <input type="text" class="input-box" placeholder="Search" v-model="searchQuery" @input="searchFilter" style="color: #4f4f4f;">
                </div>
                <!-- <div>
                    <v-btn outlined style="color: #e8e8eb;" @click="() => {filterSheet = true;}">
                        <img src="https://s3iconimages.mymedicine.com.mm/filterGrey.svg" alt="Icon">
                    </v-btn>
                </div> -->
            </div>
            </div>
        </div>
        <v-bottom-sheet  :persistent="true" v-model="specialitySheet" max-width="425px">
            <v-sheet
            class="text-center"
            height="370px"
            >
            <div class="searchStateBottomContainer">
            <div>
                <div class="selectRegionBottomSheetHeading">
                    <div>{{$t("Customer.OpdAppointments.Select_Speciality")}}</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="closeBottomSheet" style="cursor: pointer;"/>
                </div>
                <div class="" >
                    <div class="input-wrapper" style="background-color: #F4F6FA; border: none; padding: 12px 16px 12px 16px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/searchIcon.svg" alt="Icon" class="input-icon">
                        <input type="text" class="input-box input-box-spec" placeholder="Search" v-model="searchQueryRegion" @input="searchFilterRegion" style="color: #4f4f4f;">
                    </div>
                </div>
            </div>    
            <div class="stateChipsContainer">
                <div class="specialtyBox" v-for="(item) in specialtyList" :key="item.id">
                    <div>{{ item.name }}</div>
                </div>
            </div>
        </div>
        </v-sheet>
        </v-bottom-sheet>
        <v-bottom-sheet  :persistent="true" v-model="filterSheet" max-width="425px">
            <v-sheet
            class="text-center"
            height="470px"
            >
            <div class="searchStateBottomContainer">
            <div>
                <div class="selectRegionBottomSheetHeading">
                    <div>Filters</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="closeBottomSheet" style="cursor: pointer;"/>
                </div>
            </div>    
            <div>
                <v-tabs vertical background-color="#f2f2f2">    
                    <v-tab>
                        <div>Gender</div>
                    </v-tab>
                    <v-tab>
                        <div>Academic Title</div>
                    </v-tab>
                    <v-tab-item>
                        <div class="filterBody"></div>
                    </v-tab-item>
                    <v-tab-item>                        
                        <div class="filterBody"></div>
                    </v-tab-item>
                    <v-tab-item>                        
                        <div class="filterBody"></div>
                    </v-tab-item>

                </v-tabs>
            </div>
            <div class="filterBottomBar">
                <div style="flex-grow: 1;"></div>
                <div style="display: flex; gap: 10px;">
                    <v-btn plain flat style="color: #1467BF; font-weight: bolder;">Clear All</v-btn>
                    <v-btn color="#48acef" style="color: white;">Apply</v-btn>
                </div>
            </div>
        </div>
        </v-sheet>
        </v-bottom-sheet>
    </div>
    
</template>

<script>
import OPDPackageSelectSpecialty from '../../../OPDPackagePages/componentsUsedInPages/OPDPackageViewHospitalPage/OPDPackageSelectSpecialty.vue';



export default {
  components: { OPDPackageSelectSpecialty },
//   components: { mainContainer, BodyContainer, HeaderContainer },
    name: 'SelectSpecialityAndSearch',
    data: function() {
        return {
            filterSheet: false,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            specialitySheet: false,
            // specialtyList: ['General Surgery', 'Brain and Nerves', 'Heart Disease', 'Orthopaedical Surgery', 'General Surgery', 'Brain and Nerves', 'Heart Disease', 'Orthopaedical Surgery'],
            stateList: ['Yangoon', 'Akjhdyux', 'kjldsffds yunsdf', 'dsfsdafsdf dsfds', 'sdafsdf sdfsdf', 'Akjhdyux', 'kjldsffds yunsdf', 'kjldsffds yunsdf', 'dsfsdafsdf dsfds', 'sdafsdf sdfsdf', 'Akjhdyux', 'kjldsffds yunsdf', 'dsfsdafsdf dsfds', 'sdafsdf sdfsdf']
        };
    },
    async mounted() {
    },
    computed: {
        specialtyList () {
            return this.$store.state.OPDConfigCommonState.opdSpec;        
        },
    },
    methods: {
        searchFilter() {
            if (this.searchQuery.length >= 3) {
                this.$store.dispatch('searchDoctorInHospital', { searchQuery: this.searchQuery});
            } else if (this.searchQuery.length == 0) {
                this.$store.dispatch('removeSearchQueryDoctorInHospital', { searchQuery: this.searchQuery});
            }
        },
        closeBottomSheet() {
            this.specialitySheet = false;
        },
        openRegionSelector() {
            this.specialitySheet = true;
        },
        async loadHomePageData() {

        },
    },
};

</script>
<style  scoped>
.filterBottomBar {
    display: flex;
    padding: 10px 16px 10px 16px;
    box-sizing: border-box;
    align-items: center;
    border-top: 1px solid #e8e8eb;

}
.specialtyBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 40px 10px 40px;
    box-sizing: border-box;
    border-bottom: 0.1px solid #e8e8eb;
    cursor: pointer;
}
.input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #e8e8eb;
    border-radius: 6px;
    padding: 5px 10px;
    background-color: #f9f9f9;
}
.input-icon {
    height: 20px; /* Adjust based on your icon's size */
    margin-right: 10px;
}
.filterBody {
    min-height: 360px;
}
.input-box {
    border: none;
    outline: none;
    background-color: transparent;
    flex-grow: 1;
}
.mainContainer {
    width: 100%;
    background-color: white;
    
}
.bottomBorder {
    border-bottom: 1px solid #e8e8eb;
}
.searchHospital {
    width: 100%;
    border-bottom: 1px solid #e8e8eb;
    padding: 12px 16px 12px 16px;


}
.v-input >>> .v-text-field__details {
    display: none !important;
}
.stateName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.stateList {
    font-weight: 400;
    font-size: 14px;
    color: #4f4f4f;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.selectRegionBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.stateChipsContainer {
    display: flex;
    flex-wrap: wrap;
    /* gap: 8px; */
    flex-grow: 1;
    overflow-y: scroll;
    max-height: 250px;
}
.stateBottomSheetBottomBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px 12px 16px;
    border-top: 1px solid #e8e8eb;
}
.searchStateBottomContainer {
    display: flex;
    flex-direction: column;
}
.v-dialog__content >>> .v-bottom-sheet {
    box-shadow: none !important;
}
.bottomBorder >>> .v-input {
    margin: 0;
}
.bottomBorder >>> .v-messages {
    display: none;
}
.bottomBorder >>> .v-input__slot {
    margin: 0;
}
</style>