<template>
    <div class="mainContainer">
        <div class="headerHeading" v-if="showHospitalInfoLoader"> 
            <hospital-skeleton-loader />
        </div>
        <div  v-else class="headerHeading">
            <img :src="hospitalData.hospitalBanner" alt="" style=" object-fit: contain; width: 100%; height: 187px;">
            <div class="hospitalLogo">
                <img :src="hospitalData.hospitalLogo" style="max-width: 100%; max-height: 100%; object-fit: contain; border-radius: inherit;"/>
            </div>            
        </div>
    </div>
</template>

<script>
import hospitalSkeletonLoader from '../opdHomePage/hospitalSkeletonLoader.vue';

export default {
    components: { hospitalSkeletonLoader },
    name: 'HospitalBannerComp',
    data: function() {
        return {
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            searchSheet: false,
        };
    },
    computed: {
        showHospitalInfoLoader () {
            return this.$store.state.particularHospitalState.fetchingHospitalInfo;        
        },
        hospitalData () {
            return this.$store.state.particularHospitalState.hospitalInfo;        
        },
    },
    async mounted() {},
    methods: {
        closeBottomSheet() {
            this.searchSheet = false;
        },
        openRegionSelector() {
            this.searchSheet = true;
        },
        async loadHomePageData() {

        },
    },
};
</script>
<style  scoped>
.mainContainer {
    width: 100%;
    background-color: white;
}
.headerHeading {
    width: 100%;
    color: Black;
    font-weight: 500;
    /* padding: 12px 20px 12px 20px; */
    border-bottom: 1px solid #e8e8eb;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 56px;
    max-width: 56px;
    min-width: 56px;
    min-height: 56px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    position: absolute;
    bottom: 16px;
    left: 16px;
}
.hostpitalDoctorButton {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 14px 16px 14px 16px;
    gap: 12px;
    border-bottom: 1px solid #e8e8eb;
}
.searchHospital {
    width: 100%;
    border-bottom: 1px solid #e8e8eb;
    padding: 12px 16px 12px 16px;
}
.v-input >>> .v-text-field__details {
    display: none !important;
}
.stateList {
    font-weight: 400;
    font-size: 14px;
    color: #4f4f4f;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.selectRegionBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.stateChipsContainer {
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    flex-grow: 1;
    overflow-y: scroll;
    max-height: 200px;
}
.stateBottomSheetBottomBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px 12px 16px;
    border-top: 1px solid #e8e8eb;
}
.searchStateBottomContainer {
    display: flex;
    flex-direction: column;
}
.v-dialog__content >>> .v-bottom-sheet {
    box-shadow: none !important;
}
.selectSpecialityContainer {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e0e0e0;
}
</style>